// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import type { ComponentType } from 'react';

type PropsT = {
  logoUrl: ?string
};

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
`;

const LogoImage = styled.img`
  max-width: 100%;
  max-height: 6rem;
`;

const Logo: ComponentType<PropsT> = ({ logoUrl }: PropsT) =>
  logoUrl ? (
    <StyledLink to="/">
      <LogoImage src={logoUrl} alt="logo" />
    </StyledLink>
  ) : null;

export default Logo;
